/*
 * CUSTOMER COLORS
 */

$white: #fff;
$black: #000;

$brand-success: #a6c733;
$brand-info: #57bdda;
$brand-warning: #e5c649;
$brand-danger: #ed7878;

$brand-primary: #0d5229;
$brand-secondary: #636466;
$title-color: #ff6e28;
$placeholder: #c7c7c7;
$elements: #e0e0e0;
$modal: #fafafa;
$background-1: #f9f9f9;
$background-2: #ffffff;
$mockup: #d9d9d9;

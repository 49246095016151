.adicionar-foto {
  margin-bottom: 4rem;
}
.adicionar-video {
  margin-top: 10rem;
  margin-bottom: 4rem;
  .botao_alterar {
    margin-top: 5rem;
  }
}

@import "../../sass/colors.scss";

.logo-input-container {
  display: flex;
  padding: 0px 1rem;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  font-weight: normal;
  font-size: 2.5rem;
  color: $placeholder;
  background-color: $white;
  padding: 1rem 4rem;
  border-radius: 10rem;
  width: 100%;
  margin: 0;
  height: 6rem;
  cursor: pointer;
  img {
    border-radius: 10rem;
    padding: 3px;
    border: 1px solid $placeholder;
    width: 3rem;
  }

  > input[type="file"] {
    display: none;
  }
}

.titulo-modal {
  font-size: 3rem;
  padding-top: 3rem;
  text-align: center;
  color: $brand-secondary;
}
.imagem-container {
  width: 100%;
  overflow: hidden;
  .imagem {
    width: 100%;
    .crop-custom-class {
      border-radius: 15px;
      max-width: 100%;
      min-width: 30rem;
    }
  }
}
